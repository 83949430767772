import React from "react";
import { createTheme, styled } from "@mui/material/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import { BrandList } from "../../../components/src/BrandList.web";
import { Typography } from "@mui/material";
import FullWidthTabComponent from "../../../components/src/LandingPageTabList";
import ImageCarousel from "../../../components/src/SliderComponent";
import LandingPageWebController, { Props } from "./LandingPageWebController.web";
import { rightDots, leftDots } from "./assets";

// Styled components using MUI's `styled` method
const LandingPageContainer = styled("div")({
  position: "relative",
  backgroundColor: "#EDE6DC",
  overflowX: "hidden",
});

const ContentContainer = styled("div")({
  width: "100%",
  boxSizing: "border-box",
});

const CarouselContainer = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginTop: "-90px",
  marginBottom: "-100px",
  justifyContent: "space-between",
  position: "relative",
  zIndex: 1,
});

const StyledTypography = styled(Typography)({
  marginTop: "30px",
  fontFamily: "Big Caslon",
  fontSize: 30,
  fontWeight: "500",
  textAlign: "center",
  color: "#53503F",
  lineHeight: "36.27px",
  textTransform: "uppercase",
});

const BrandSection = styled("div")({
  position: "relative",
  zIndex: 99,
});

export default class LandingPage extends LandingPageWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <LandingPageContainer data-test-id="landingPageContainer">
        <AppHeader />
        <ContentContainer>
          <FullWidthTabComponent
            categoriesOnHoverList={this.state.categoriesOnHoverList}
            getCategoriesOnHover={this.getCategoriesOnHover}
            categoriesList={this.state.categoriesArray}
          />

          <ImageCarousel landingPageData={this.state.landingPagesData} />
          <CarouselContainer>
            <img width={300} height={300} src={leftDots} alt="gvc" />
            <StyledTypography>All Genuine BRANDS</StyledTypography>
            <img width={300} height={300} src={rightDots} alt="gvc" />
          </CarouselContainer>

          <BrandSection data-test-id="brandSection">
            <BrandList
              brandsList={this.state?.brandsAndCataloguesList?.brands}
              categoryLable={"Brands"}
              shwoBrandComponent={true}
              ViewAllLink={"/"}
            />
            <BrandList
              brandsList={this.state?.brandsAndCataloguesList?.categories}
              categoryLable={"Watches"}
              shwoBrandComponent={false}
              ViewAllLink={"/"}
            />
          </BrandSection>
        </ContentContainer>
        <Footer />
      </LandingPageContainer>
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
});

const webStyle = {};

// Customizable Area End
