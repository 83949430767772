import React, { useRef, useState, useEffect } from 'react';
import { Typography, Button, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { SingleProductComponent } from './SingleProductLandingPage.web';
const rightArrow =require("./socialmediaicons/rightarrow.svg").default
const leftArrow =require("./socialmediaicons/leftarrow.svg").default

const Container = styled('div')({
  maxWidth: "100%",
  margin: "60px 0",
  boxSizing: "border-box",
});

const BrandHeader = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 40px",
});

const BrandName = styled(Typography)({
  fontFamily: "Avenir",
  fontSize: "30px",
  fontWeight: "800",
  color: "#53503F",
});

const CustomLink = styled(Link)({
  fontFamily: "Avenir",
  fontSize: "24px",
  fontWeight: "400",
  cursor: "pointer",
  textDecoration: "none",
  color: "#53503F",
});

const BrandContainer = styled('div')({
  width: "100%",
  marginTop: "40px",
  display: "flex",
  paddingLeft:"40px",
  boxSizing: "border-box",
  overflowX: "auto",
  overflowY: "hidden", 
  gap:"2.9rem",
  whiteSpace: "nowrap",
  "&::-webkit-scrollbar": {
    height: "0px", 
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc", 
    borderRadius: "0px",
  },
});

const BrandItem = styled('div')({
  boxSizing:"border-box",
  height: "280px",
  width: "250px",
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  border: "10px solid #FFFFFF",
  backgroundColor: "#EDE6DC", 
  marginTop: "20px",
});

const BrandContent = styled('div')({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  borderRadius: "8px",
});

const ShopButton = styled(Button)({
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Big Caslon",
  width: "126px",
  height: "44px",
  padding: "10px 16px",
  borderRadius: "8px",
  color: "#EDE6DC",
  backgroundColor: "#53503F",
  "&:hover": {
    color: "#EDE6DC",
    backgroundColor: "#53503F",
  }
});

const CategoriesContainer = styled('div')({
  margin: "40px 0px",
  display: "flex",
  gap: "2.9rem",
  padding: "0px 4px 0px 40px !important",
  boxSizing: "border-box",
  overflowX: "auto", 
  overflowY: "hidden",
  whiteSpace: "nowrap",
  paddingLeft:"50px",
  "&::-webkit-scrollbar": {
    height: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "0px",
  },
});

const ScrollButton = styled("button")({
  borderRadius: "50%",
  height: "48px",
  boxSizing:"border-box",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  width: "48px",
  background: "white",
  color: "black",
  fontWeight: "bold",
  cursor:"pointer",
  border: "none",
  "&:hover":{
    backgroundColor:"white"
  }
});

interface BrandListProps {
  shwoBrandComponent: boolean;
  categoryLable: string;
  ViewAllLink: string;
  brandsList: any
}

export function BrandList({
  brandsList,
  shwoBrandComponent,
  categoryLable,
  ViewAllLink,
}: BrandListProps) {
  const containerRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());
  const [scrollButtonStates, setScrollButtonStates] = useState<{ 
    [key: number]: { 
      left: boolean, 
      right: boolean, 
      noscrollableToRight: boolean, 
      noscrollableToLeft: boolean 
    } 
  }>({});
  
  // Initialize button states
  const [initialLoad, setInitialLoad] = useState(true);
  
  const updateButtonStates = (index: number) => {
    const container = containerRefs.current.get(index);
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      const canScrollRight = scrollLeft + clientWidth < scrollWidth;
      const canScrollLeft = scrollLeft > 0;
  
      setScrollButtonStates(prev => ({
        ...prev,
        [index]: {
          left: canScrollLeft,
          right: canScrollRight,
          noscrollableToRight: !canScrollRight,
          noscrollableToLeft: !canScrollLeft,
        },
      }));
  
      // Set initial load state to false once button states are updated
      if (initialLoad) {
        setInitialLoad(false);
      }
    }
  };
  
  const scrollLeft = (index: number) => {
    const container = containerRefs.current.get(index);
    if (container) {
      container.scrollBy({
        left: -300,
        behavior: "smooth",
      });
      updateButtonStates(index);
    }
  };
  
  const scrollRight = (index: number) => {
    const container = containerRefs.current.get(index);
    if (container) {
      container.scrollBy({
        left: 300,
        behavior: "smooth",
      });
      updateButtonStates(index);
    }
  };
  
  useEffect(() => {
    brandsList?.forEach((brand: any, index: number) => {
      if (brand?.catalogues && brand.catalogues.length > 5) {
        updateButtonStates(index + 1);
      }
    });
  }, [brandsList]);

  return shwoBrandComponent ? (
    <Container
      style={{ marginBottom: brandsList?.length > 5 ? "-60px" : "60px" }}
    >
      <BrandHeader>
        <BrandName>{categoryLable}</BrandName>
        <CustomLink to={ViewAllLink}>View all</CustomLink>
      </BrandHeader>
      <BrandContainer
        ref={(el) => containerRefs.current.set(0, el)}
        onScroll={() => updateButtonStates(0)}
      >
        {brandsList?.map((item: any, index: number) => (
          <BrandItem key={index}>
            <BrandContent>
              <img
                height={67}
                width={126}
                src={item?.attributes?.image_url}
                alt=""
              />
              <ShopButton>Shop Now</ShopButton>
            </BrandContent>
          </BrandItem>
        ))}
      </BrandContainer>
      {brandsList?.length > 5 && (
        <div
          style={{
            marginLeft: "10px",
            marginRight: "35px",
            paddingLeft: "40px",
            position: "relative",
            top: "-170px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!initialLoad && !scrollButtonStates[0]?.noscrollableToLeft ? (
            <ScrollButton onClick={() => scrollLeft(0)}>
              <img height={20} width={15} src={rightArrow} alt="leftarrow" />
            </ScrollButton>
          ) : (
            <span></span>
          )}
          {!scrollButtonStates[0]?.noscrollableToRight ? (
            <ScrollButton
              style={{ marginRight: "87px" }}
              onClick={() => scrollRight(0)}
            >
              <img height={20} width={15} src={leftArrow} alt="rightarrow" />
            </ScrollButton>
          ) : (
            <span></span>
          )}
        </div>
      )}
    </Container>
  ) : (
    <>
      {brandsList?.map(
        (brand: any, index: number) =>
          brand?.catalogues && (
            <Container
              key={index}
              style={{
                marginBottom:
                  index + 1 === brandsList.length
                    ? "-5px"
                    : brand?.catalogues.length > 5
                    ? "-90px"
                    : "60px",
              }}
            >
              <BrandHeader>
                <BrandName>{brand?.name}</BrandName>
                <CustomLink to={ViewAllLink}>View all</CustomLink>
              </BrandHeader>

              <>
                <CategoriesContainer
                  ref={(el) => containerRefs.current.set(index + 1, el)}
                  onScroll={() => updateButtonStates(index + 1)}
                >
                  {brand?.catalogues?.map((item: any, idx: number) => (
                    <SingleProductComponent key={idx} product={item} />
                  ))}
                </CategoriesContainer>

                {brand?.catalogues.length > 5 && (
                  <div
                    style={{
                      marginLeft: "10px",
                      paddingLeft: "40px",
                      marginRight: "35px",
                      position: "relative",
                      top: "-260px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* Scroll left button */}
                    {!scrollButtonStates[index + 1]?.noscrollableToLeft ? (
                      <ScrollButton
                        onClick={() => scrollLeft(index + 1)}
                        disabled={!scrollButtonStates[index + 1]?.left}
                      >
                        <img
                          height={20}
                          width={15}
                          src={rightArrow}
                          alt="leftarrow"
                        />
                      </ScrollButton>
                    ) : (
                      <span></span>
                    )}

                    {/* Scroll right button */}
                    {!scrollButtonStates[index + 1]?.noscrollableToRight ? (
                      <ScrollButton
                        style={{ marginRight: "85px" }}
                        onClick={() => scrollRight(index + 1)}
                        disabled={!scrollButtonStates[index + 1]?.right}
                      >
                        <img
                          height={20}
                          width={15}
                          src={leftArrow}
                          alt="rightarrow"
                        />
                      </ScrollButton>
                    ) : (
                      <span></span>
                    )}
                  </div>
                )}
              </>
            </Container>
          )
      )}
    </>
  );
}
