import React from 'react'
import {
  Typography,
} from "@mui/material";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';


export function SingleProductComponent({product}:any) {
  return (
    <div
      style={{
        width: "250px",
        height:"340px",
        boxSizing:"border-box",
        display: "flex",
        flexDirection: "column",
        padding: "3px",
        borderRadius: "8px",
        border: "1px solid #FFFFFF",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        className="imgDiv"
        style={{
          height: "230px",
          width: "234px",
          borderRadius: "8px",
          backgroundColor: "#EDE6DC",
          display:"flex",
          flexDirection:"column",
          justifyContent:"center",
          alignItems:"center",
          margin:"4px"
        }}
      >
        <div style={{width:"100%"}}>

        <div style={{cursor:"pointer",padding:"2px",float:"right",marginRight:"8px",borderRadius:"6px", display:"flex",justifyContent:"center",alignItems:"center",height:"24px",width:"24px",backgroundColor:"#53503F"}}>
        <FavoriteIcon  sx={{
        color: '#EDE6DC',
        '& path': {
          fill: '#EDE6DC', 
        },
      }}/>
        </div>
        </div>
        <img
        height={165} width={165}
          src={product?.attributes?.image_url}
          alt=""
        />
      </div>
      <Typography
        style={{
          fontFamily: "Avenir",
          fontSize: "18px",
          fontWeight: 800,
          lineHeight: "22px",
          textAlign: "left",
          color: "#53503F",
          marginLeft:"6px",
          marginBottom:"9px",
          marginTop:"7px"
        }}
      >
        {product?.attributes?.brand}
      </Typography>
      <span
        style={{
          fontFamily: "Avenir",
          fontSize: "16px",
          fontWeight: 350,
          textAlign: "left",
          marginLeft:"6px",
          marginBottom:"9px",
          display: "inline-block",
          maxWidth: "100%",
          wordWrap: "break-word",
          whiteSpace: "normal",
          color:"#53503F"
        }}
      >
       {product?.attributes?.name}
      </span>
      <Typography
        style={{
          fontFamily: "Avenir",
          fontSize: "20px",
          fontWeight: 900,
          textAlign: "left",
          marginLeft:"6px",
          marginBottom:"13px",
          color:"#53503F"
        }}
      >
        {product?.attributes?.price}
      </Typography>
    </div>
  );
}

const webStyle = {
  
};
